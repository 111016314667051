var _this=this,_jsxFileName="/home/runner/work/prd-nxg-smartapps/prd-nxg-smartapps/packages/app@smartott/src/whitelabel/pages/index.js";import React from'react';
import Loader from'@24i/nxg-sdk-gluons/src/components/ui/Loader';

var Index=function Index(){return(
React.createElement(Loader,{
additionalStyles:{
minHeight:'100%',
justifyContent:'center'
},__self:_this,__source:{fileName:_jsxFileName,lineNumber:5,columnNumber:5}}
));};


export default Index;